<template>
  <div>
    <div class="marcas-main-bg catalog-main-bg">
      <div class="w-full h-full opacus-bg block">
        <div class="container h-full">
          <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h2 class="main-text">Nuestros Productos</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12">
      <div class="container font-bold text-center">
        <h2 class="text-3xl">Busqueda de productos</h2>
      </div>


      <div class=" grid grid-cols-none lg:grid-cols-5 justify-items-center">
        <div id="filters" class="text-left font-bold">

          <div class="mb-3 text-center lg:text-left">
            <h2 class="cursor-pointer text-2xl" @click="handleCollapse('brands')">Marcas</h2>
            <b-collapse>
              <div v-show="collapse.brands">
                <t-checkbox-group name="brandsFilter" :options="brands" v-model="brandsFilters"></t-checkbox-group>
              </div>
            </b-collapse>
          </div>

          <div class="mb-3 text-center lg:text-left">
            <h2 class="cursor-pointer text-2xl" @click="handleCollapse('categories')">Categorias</h2>
            <b-collapse>
              <div v-show="collapse.categories">
                <t-checkbox-group name="categoriesFilter" :options="categories" v-model="categoriesFilters"></t-checkbox-group>
              </div>
            </b-collapse>
          </div>

        </div>
        <div id="products" class="col-span-3 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 justify-items-center">
          <div
            class="cursor-pointer mx-3 px-6"
            v-for="(product) in productsFiltered"
            :key="product.id"
          >
            <div class="w-60 h-60 lg:w-80 lg:h-80">
              <img class="w-full" :src="product.image" alt="" />
            </div>
            <div class="px-6">
              <p class="text-xs nop font-semibold break-words text-center">
                ${{ product.regular_price }} USD <br />
                {{ product.name }} <br />
                {{ product.brand }} <br />
                *No incluye IVA
            </p>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Catalog",
  data() {
    return {
      collapse: {
        brands:false,
        categories:false,
      },
      products: [],
      productsFiltered: [],
      brands: [],
      categories: [],
      brandsFilters:[],
      categoriesFilters:[],
      categoriesResponse:[]
    };
  },
  watch: {
    categoriesFilters: function(){
      this.handleFilterProducts()
    },
    brandsFilters: function(){
      this.handleFilterProducts()
    },

  },
  methods: {
    handleGetProducts() {
      axios
        .get("http://api.morosini.mx/products")
        .then((response) => {
          let products = response.data.products
          this.categoriesResponse = response.data.tags;
          products.map(product => {
            product.tags = response.data.tags.filter(tag => tag.product_id == product.id).map(tag => tag.tag)
            product.images = response.data.images.filter(image => image.product_id == product.id).map(image => image.image)
          })

          this.products = products;
          this.productsFiltered = products;

          if(this.$route.query.brand){
            const param = this.$route.query.brand.toUpperCase();
            this.brandsFilters = [param]
            this.productsFiltered = this.productsFiltered.filter(({brand}) => brand == param)
            let query = Object.assign({}, this.$route.query);
            delete query.brand;
            this.$router.replace({ query });
          }

          this.brands = [
            ...new Set(
              response.data.products.map(({ brand }) => {
                return brand;
              })
            ),
          ];
          this.categories = [...new Set(response.data.tags.map(({ tag }) => tag))];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCollapse(filter) {
      this.collapse[filter] = !this.collapse[filter];
    },
    handleFilterProducts(){
      const handleFilterBrands = (product) => {
        if(!this.brandsFilters.length) return true;
        return this.brandsFilters.includes(product.brand);
      }, handleFilterCategories = (product) => {
        if(!this.categoriesFilters.length) return true;
        return !!product.tags.filter(tag => this.categoriesFilters.includes(tag)).length
      }

      if(!this.brandsFilters.length && !this.categoriesFilters.length){
        this.productsFiltered = this.products
      }else {
        this.productsFiltered = this.products.filter(product => handleFilterBrands(product) && handleFilterCategories(product))
      }

      if(this.brandsFilters.length){
        this.categories = [...new Set(this.categoriesResponse.filter(({product_id}) => this.productsFiltered.map(({id}) => id).includes(product_id)).map(({ tag }) => tag))];
      }else {
        this.categories = [...new Set(this.categoriesResponse.map(({ tag }) => tag))];
      }
      //console.log([...new Set(this.categoriesResponse.filter(({product_id}) => this.productsFiltered.map(({id}) => id).includes(product_id)).map(({ tag }) => tag))])
  
    }
  },
  created() {
    this.handleGetProducts();
  },
  mounted(){
  }
};
</script>

<style scoped>
.nop{
  font-size: 0.75rem !important
}
</style>